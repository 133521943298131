const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const apiKey = process.env.REACT_APP_API_KEY;

let cachedToken = null; // Cache the token to avoid fetching it repeatedly

export const fetchToken = async () => {
    if (cachedToken) {
        return cachedToken; // Return the cached token if it exists
    }

    try {
        const response = await fetch(`${apiEndpoint}/auth/token`, {
            method: 'GET',
            headers: {
                'X-Api-Key': apiKey, // API key from environment variables
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch token');
        }

        const token = await response.text();
        cachedToken = token; // Cache the token
        return token;
    } catch (error) {
        console.error('Error fetching token:', error);
        throw error;
    }
};
