import React, { useState } from 'react';
import { fetchToken } from './tokenUtils';
import './RemoveAccount.css'; // Import CSS file for styling

const SApnaSochEndPoint = process.env.REACT_APP_API_ENDPOINT;

const RemoveAccount = () => {
    const [mobileNumber, setMobileNumber] = useState('+91');
    const [otp, setOtp] = useState('');
    const [userId, setUserId] = useState('');
    const [message, setMessage] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);

    const sendOTP = async () => {
        try {
            const token = await fetchToken();
            if (!token) {
                setMessage('Failed to retrieve access token.');
                return;
            }

            const response = await fetch(`${SApnaSochEndPoint}/otp/SendVerifyOTP`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ to: mobileNumber }),
            });

            if (response.ok) {
                setOtpSent(true);
                setMessage('OTP sent successfully.');
            } else {
                setMessage('Failed to send OTP. Please try again.');
            }
        } catch (error) {
            setMessage('An error occurred while sending OTP.');
        }
    };

    const verifyOTP = async () => {
        try {
            const token = await fetchToken();
            if (!token) {
                setMessage('Failed to retrieve access token.');
                return;
            }

            const response = await fetch(`${SApnaSochEndPoint}/otp/ValidateOTP`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ to: mobileNumber, otpCode: otp }),
            });
            if (response.ok) {
                setOtpVerified(true);
                setMessage('OTP verified successfully.');
                await fetchUserDetails();
            } else {
                setMessage('Invalid OTP. Please try again.');
            }
        } catch (error) {
            setMessage('An error occurred while verifying OTP.');
        }
    };

    const fetchUserDetails = async () => {
        try {
            const token = await fetchToken();
            if (!token) {
                setMessage('Failed to retrieve access token.');
                return;
            }

            const response = await fetch(`${SApnaSochEndPoint}/users/getuserbymobilenumber?mobileNumber=${encodeURIComponent(mobileNumber)}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const userData = await response.json();
                setUserId(userData?.id);
            } else {
                setMessage('No user found with this mobile number.');
            }
        } catch (error) {
            setMessage('An error occurred while fetching user details.');
        }
    };

    const deleteUserAccount = async () => {
        if (!userId) {
            setMessage('User details not available for deletion.');
            return;
        }

        try {
            const token = await fetchToken();
            if (!token) {
                setMessage('Failed to retrieve access token.');
                return;
            }

            const response = await fetch(`${SApnaSochEndPoint}/users/deleteuserbyid?userId=${userId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                setMessage('User account deleted successfully.');
            } else {
                setMessage('Failed to delete user account.');
            }
        } catch (error) {
            setMessage('An error occurred while deleting the account.');
        }
    };

    const handleMobileChange = (e) => {
        const input = e.target.value;
        // Ensure that the input always starts with +91
        if (!input.startsWith('+91')) {
            setMobileNumber('+91');
        } else {
            setMobileNumber(input);
        }
    };

    return (
        <div className="remove-account-container">
            <h2>Permanently Delete Account</h2>
            <div className="form">
                <div className="input-group">
                    <input
                        type="text"
                        value={mobileNumber}
                        onChange={handleMobileChange}
                        placeholder="Enter your mobile number"
                        disabled={otpSent}
                        className="input-field"
                    />
                    <button onClick={sendOTP} disabled={otpSent} className="button">
                        Send OTP
                    </button>
                </div>

                {otpSent && (
                    <div className="input-group">
                        <label>One-Time Password (OTP):</label>
                        <input
                            type="text"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            placeholder="Enter OTP"
                            disabled={otpVerified}
                            className="input-field"
                        />
                        <button onClick={verifyOTP} disabled={otpVerified} className="button">
                            Verify OTP
                        </button>
                    </div>
                )}

                {otpVerified && userId && (
                    <div className="delete-group">
                        <button onClick={deleteUserAccount} className="button delete-button">
                            Delete Account
                        </button>
                    </div>
                )}

                {message && <p className="message">{message}</p>}
            </div>
        </div>
    );
};

export default RemoveAccount;
