import React, { useState } from 'react';
import './App.css';
import { Chrono } from "react-chrono-multicard";
import { useNavigate } from 'react-router-dom';
function Home() {

    const navigate = useNavigate(); // Initialize useNavigate hook

    // Function to handle button click and navigate to /removeaccount
    const handleNavigateToRemoveAccount = () => {
        navigate('/removeaccount');
    };

    const isMobile = window.innerWidth <= 768;
    const items = [{
        title: "JUNE 2021",
        cardSubtitle: "It was a day when I was actively searching for an idea to develop a social website that seamlessly integrates ethics and enjoyment, all while preserving cultural values. My goal was to create a platform that not only fosters meaningful interactions but also upholds the essence of our diverse cultures and traditions.",
    },
    {
        title: "JULY 2021",
        cardSubtitle:
            "Now that I've got the idea, it's my turn to discover the perfect name.I aimed for a name that unmistakably resonates with India, one that instantly evokes its essence.My quest led me to search for a familiar, yet straightforward word, widely embraced.It wasn't an easy task; I wrestled with this challenge for over a week. But eventually, after persistent effort, I found the elusive name I had been seeking. However, the journey didn't end there.Despite financial constraints, I was determined to make it mine.So, I took a leap of faith and used my credit card to secure and finally purchase the domain.",

    },
    {
        title: "OCT 2021",
        cardSubtitle:
            "I found myself in the process of selecting the technology for developing my mobile app. Seeking advice from numerous seniors, browsing through online forums, and listening to varied opinions, I was met with conflicting suggestions. With no in-house developers available to execute the project, I realized the importance of choosing the technology wisely to address multiple needs. After careful consideration, I opted for React Native, grounding my decision in its potential to fulfill dual objectives efficiently and align with practical realities.",
    },
    {
        title: "JAN 2022",
        cardSubtitle:
            "As I ventured into the realm of React Native�a technology new to me�I embraced a learning phase to grasp its nuances. Prior to venturing into larger projects, I embarked on a journey of experimentation, delving into various small-scale endeavors to acquaint myself with the platform's capabilities. Through this process, I meticulously observed the platform's merits and shortcomings. Armed with these insights, I proceeded to craft a robust architectural blueprint. Only upon solidifying this groundwork did I immerse myself in the hands-on task of coding, feeling empowered by my comprehensive understanding of the technology.",
    },
    {
        title: "FEB 2022",
        cardSubtitle:
            "Transitioning to UI design, I found myself fortunate to possess an innate gift for envisioning and creating designs without the need for formal training.From a young age, I possessed a keen eye for identifying design flaws in various apps and products on the market.Interestingly, even before the advent of the bullet train, I had envisioned a solution in my mind.I foresaw that to enhance train speed, leveraging magnetic fields would be key.This ability to visualize and innovate has been a blessing, seemingly bestowed upon me by a higher power.",
    },
    {
        title: "MAR 2022",
        cardSubtitle: "Soon, I began developing the code while working a regular 9-5 software job, coding in the early mornings and late at night.Gradually, the modules were implemented.Occasionally, the project would be put on hold, and I would lose inspiration in the process.However, I found ways to regain inspiration through self- talk and persevered with the coding.",
    },
    {
        title: "OCT 2023",
        cardSubtitle: "Embarking on the journey of company registration, I navigated the bureaucratic processes diligently, leading to the official registration of my company under India's Company Act within just one month. This achievement fills me with pride as I join the ranks of numerous other enterprises that have contributed to the nation's progress.Soon thereafter, the tangible symbols of our existence arrived in the form of an emblem and a nameplate, marking the official establishment of ApnaSoch Pvt Ltd.",
    },
    {
        title: "JAN 2024",
        cardSubtitle: "After the boost in company incorporation, I took some time for myself, embarking on long walks to ponder the enhancements and innovations I could introduce to my app, aiming to revolutionize its appearance and functionality. With a newfound sense of confidence, I began to focus on refining the user interface, concentrating on crafting lightweight designs to achieve a simpler, more streamlined look."
    },
    {
        title: "JAN 2024",
        cardSubtitle: "After the boost in company incorporation, I took some time for myself, embarking on long walks to ponder the enhancements and innovations I could introduce to my app, aiming to revolutionize its appearance and functionality. With a newfound sense of confidence, I began to focus on refining the user interface, concentrating on crafting lightweight designs to achieve a simpler, more streamlined look."
    },
    {
        title: "MAR 2024",
        cardSubtitle: "After taking a break to recharge, I'm now poised for a strong comeback, with the Android app nearing completion. We're on the brink of entering the market, eagerly anticipating the success that awaits us. We're prepared to persevere until we achieve the level of success we envision"
    },
    {
        title: "AUG 2024",
        cardSubtitle: "Apna soch has been tested and release to google playstore. Google play had started reviewing it and it autoapproved to playstore once its automatically passed. Hope soch ki liye apna soch sabka dil ki liye one day !!!!"
    }
    ];

    const [showPopup, setShowPopup] = useState(false);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    return (
        <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            {/* Top Navigation Bar */}
            <div style={{
                height: 'auto', backgroundColor: '#333', color: '#fff', textAlign: 'center', display: 'flex',
                alignItems: 'center', justifyContent: 'center', paddingLeft: 20, paddingRight: 20, paddingTop: 10,
                paddingBottom: 10,
                flexWrap: 'wrap'
            }}>
                <div style={{ fontFamily: 'cursive', fontSize: 40 }}>ApnaSoch</div>
                <div style={{ marginLeft: 'auto' }}>
                    <button style={{ backgroundColor: 'white', cursor: 'pointer', fontFamily: 'cursive', borderRadius: 10, fontSize: 20, padding: 15 }} onClick={togglePopup}>Download android app</button>
                    <button style={{ backgroundColor: 'white', cursor: 'pointer', fontFamily: 'cursive', borderRadius: 10, fontSize: 20, padding: 15 }} onClick={handleNavigateToRemoveAccount}>Delete Account</button>
                </div>
            </div>

            {/* Body */}
            <div style={{ height: '90%', backgroundColor: '#eee', padding: '20px', fontFamily: 'sans-serif' }}>
                {showPopup && (
                    <div className="popup" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', zIndex: 9999, opacity: 1, width: '400px' }}>
                        <img src="https://cdn.pixabay.com/photo/2018/07/18/19/09/coming-soon-3547071_1280.png" alt="Coming Soon" style={{ maxWidth: '100%', maxHeight: '300px', marginBottom: '20px' }} />
                        <div style={{ position: 'absolute', top: '150px', left: '140px', backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '10px', borderRadius: '5px', zIndex: 999 }}>
                            <h3 style={{ margin: '0', fontFamily: 'cursive' }}>Stay tuned for exciting updates!!!</h3>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <button onClick={togglePopup} style={{ backgroundColor: '#333', color: 'white', fontFamily: 'cursive', borderRadius: 10, fontSize: 20, padding: 15, width: 200 }}>Close</button>
                        </div>
                    </div>
                )}

                <Chrono
                    items={items}
                    mode={isMobile ? "VERTICAL" : "VERTICAL_ALTERNATING"}
                    cardWidth={500}
                    enableOutline
                    theme={{
                        primary: 'black',
                        secondary: 'white',
                        cardBgColor: 'white',
                        cardForeColor: 'black',
                        titleColor: 'black',
                        titleColorActive: 'black',
                    }}
                    fontSizes={{
                        cardSubtitle: '1rem',
                        cardText: '0.8rem',
                        cardTitle: '2rem',
                        title: '1.2rem',
                    }}
                />
            </div>
        </div>
    );
}

export default Home;
