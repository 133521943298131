import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div style={styles.container}>
            <h1 style={styles.header}>Privacy Policy</h1>
            <p style={styles.text}>
                Apnasoch we operates the Apnasoch mobile application. This page informs you of our policies regarding the collection, use, and storage of personal data when you use our App.
            </p>
            <h2 style={styles.subHeader}>1. Information We Collect</h2>
            <p style={styles.text}>
                We collect the following information from users:
            </p>
            <ul style={styles.list}>
                <li><b>Phone Number:</b> We collect your phone number during registration or profile setup to verify your account and provide you with personalized services.</li>
                <li><b>Profile Information:</b> We collect basic profile information, such as your name, to enhance your user experience within the App.</li>
            </ul>
            <h2 style={styles.subHeader}>2. How We Use Your Data</h2>
            <p style={styles.text}>
                We use the collected data for the following purposes:
            </p>
            <ul style={styles.list}>
                <li><b>Account Verification:</b> We use your phone number to verify your account and manage your login.</li>
                <li><b>Profile Customization:</b> Your profile information is used to personalize your experience within the App.</li>
            </ul>
            <h2 style={styles.subHeader}>3. Data Storage and Security</h2>
            <p style={styles.text}>
                Your data is securely stored in our database. We take reasonable measures to protect your personal information from unauthorized access, alteration, or disclosure.
            </p>
            <h2 style={styles.subHeader}>4. Data Sharing and Disclosure</h2>
            <p style={styles.text}>
                We do not share your personal information with any third parties. All data collected is used solely for the purposes mentioned above and is stored securely in our database.
            </p>
            <h2 style={styles.subHeader}>5. Your Data Rights</h2>
            <p style={styles.text}>
                You have the right to:
            </p>
            <ul style={styles.list}>
                <li>Access and update your personal information within the App.</li>
                <li>Request the deletion of your account and associated data at any time.</li>
            </ul>
            <h2 style={styles.subHeader}>6. Changes to This Privacy Policy</h2>
            <p style={styles.text}>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy within the App.
            </p>
            <h2 style={styles.subHeader}>7. Contact Us</h2>
            <p style={styles.text}>
                If you have any questions about this Privacy Policy, please contact us at:
            </p>
            <ul style={styles.list}>
                <li><b>Email:</b> spuramsetti@apnasoch.com</li>
                <li><b>Phone:</b> +91 63628 42740</li>
            </ul>
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '800px',
        margin: '0 auto',
        padding: '20px',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        fontFamily: '"Arial", sans-serif',
        textAlign: 'left',
    },
    header: {
        fontSize: '32px',
        color: '#2c3e50',
        textAlign: 'center',
        marginBottom: '20px',
    },
    subHeader: {
        fontSize: '24px',
        color: '#34495e',
        margin: '20px 0 10px',
    },
    text: {
        fontSize: '18px',
        lineHeight: '1.6',
        color: '#333',
        marginBottom: '20px',
    },
    list: {
        fontSize: '18px',
        lineHeight: '1.6',
        color: '#333',
        paddingLeft: '20px',
        listStyleType: 'disc',
        marginBottom: '20px',
    },
};

export default PrivacyPolicy;
